<template>
  <div id="mobile">
    <component :is="currentLayout">
      <template #controls>
        <editor />
      </template>
      <template #content>
        <div class="mt-24 flex flex-col-reverse sm:flex-col sm:ml-4 sm:mt-0">
          <div
            class="flex flex-col-reverse mx-auto sm:flex-row sm:mx-0 sm:justify-between "
          >
            <t-button primary class="mt-2" @click="handleNewTimer()">
              + New Timer</t-button
            >
            <div class="flex flex-col sm:flex-row mt-2 gap-2">
              <t-button outline @click.native="handleSaveTimer()">
                Save timer
              </t-button>
              <t-button
                class="md:w-24"
                primary
                :loading="getCodeIsLoading"
                @click.native="showGetCodeModal()"
                >Get Code</t-button
              >
            </div>
          </div>

          <div
            class="
            grid grid-cols-3 gap-1 sm:gap-2 
            bg-white rounded-2xl w-full mt-5 sm:p-2"
          >
            <span
              class="col-start-2 flex justify-center sm:order-1 sm:col-start-1 sm:justify-start sm:ml-2 text-sm"
              @click.prevent="toggleView"
            >
              <a href="#" :class="toggleBtnClasses">Timer Preview</a>
            </span>

            <span
              class="
            col-span-3 order-3 flex justify-center
            sm:col-auto sm:col-start-3 sm:order-2 sm:justify-end sm:mr-2
            text-sm"
            >
              Timer width:
              <p class="text-blue-500 font-semibold">
                {{ dimensions.width }} px
              </p>
              / height:
              <p class="text-blue-500 font-semibold">
                {{ dimensions.height }} px
              </p>
            </span>

            <div class="col-span-3 sm:order-3">
              <image-preview
                v-if="view === 'image'"
                class="mx-auto"
                :timerId="timer.id"
                :timerName="timer.name"
              ></image-preview>
              <timer-preview v-if="view === 'code'" />
            </div>
          </div>
        </div>
      </template>
    </component>
    <modal ref="getCodeModal">
      <div class="flex flex-col gap-2 items-center p-5">
        <p class="text-2xl text-green font-extrabold">Get your code</p>
        <p class="text-xs">Copy&Paste it in your email</p>
        <textarea
          v-if="timer.id.length > 0"
          class="block w-64 sm:w-full text-xs font-medium mx-auto bg-gray-100 rounded px-2 whitespace-nowrap overflow-x-auto"
          rows="4"
          cols="91"
          >{{ htmlCode }}</textarea
        >
        <p v-else>
          Error getting timer code!
        </p>
        <t-button
          danger
          class="mt-4"
          @click.native="$refs.getCodeModal.closeModal()"
          >CLOSE</t-button
        >
      </div>
    </modal>
    <confirm-dialogue ref="confirmDialogue">
      <template #content>
        <p class="">Are you sure you want to create a new timer?</p>
        <p class="mt-2">All unsaved changes to the current one will be lost</p>
      </template>
      <template #controls>
        <t-button
          secondary
          class="mt-4"
          @click.native="$refs.confirmDialogue._cancel()"
          >CANCEL</t-button
        >
        <t-button
          primary
          class="mt-4"
          @click.native="$refs.confirmDialogue._confirm()"
          >CONTINUE</t-button
        >
      </template>
    </confirm-dialogue>
    <alert
      v-if="alertIsShown"
      @on-alert-click="alertIsShown = false"
      :type="notification.type"
    >
      {{ notification.msg }}
    </alert>
  </div>
</template>

<script>
import DLayout from "../layouts/DLayout.vue";
import MLayout from "../layouts/MLayout.vue";

import TButton from "../components/core/Button.vue";
import BaseInput from "../components/core/BaseInput.vue";

import Editor from "../components/Editor.vue";
import TimerPreview from "../components/TimerPreview.vue";
import ImagePreview from "../components/ImagePreview.vue";

import Alert from "../components/core/Alert.vue";
import Modal from "../components/core/Modal.vue";
import ConfirmDialogue from "../components/ConfirmDialogue.vue";

import { defaultTimer } from "@/services/editor.js";

import { mapGetters } from "vuex";

const BASE = process.env.VUE_APP_API_BASE;

export default {
  name: "EditorPage",
  components: {
    DLayout,
    MLayout,
    Editor,
    TButton,
    Modal,
    BaseInput,
    TimerPreview,
    ImagePreview,
    Alert,
    ConfirmDialogue,
  },

  data() {
    return {
      alertIsShown: false,
      view: "",
      email: "",
      getCodeModalShown: false,
      sendCodeModalShown: false,
      windowWidth: "",
      notification: {
        type: "",
        msg: "",
      },
      getCodeIsLoading: false,
    };
  },

  methods: {
    async handleNewTimer() {
      const confirm = await this.$refs.confirmDialogue.show();
      console.log(this.$refs);
      if (confirm) {
        this.setDefault();
      }
    },
    showGetCodeModal() {
      if (!this.currentUser.is_authenticated) {
        this.$router.push("/signin");
      } else if (!this.currentUser.subscription) {
        this.$router.push("/account");
      } else {
        (async () => {
          this.getCodeIsLoading = true;
          await this.handleSaveTimer();
          await this.$store.dispatch(
            "bindings/getBindingsByTimerId",
            this.timer.id
          );
          await this.$refs.getCodeModal.openModal();
          await (() => {
            this.getCodeIsLoading = false;
          })();
        })();
      }
    },

    toggleView() {
      if (this.view === "image") {
        this.view = "code";
      } else {
        this.view = "image";
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    showAlert(type, msg) {
      this.notification.type = type;
      this.notification.msg = msg;
      this.alertIsShown = true;
      setTimeout(() => {
        this.alertIsShown = false;
      }, 5000);
    },

    async handleSaveTimer() {
      if (this.currentUser && !this.currentUser.is_authenticated) {
        this.$router.push("/signin");
      }

      if (this.timer && this.timer.id.length) {
        await this.$store.dispatch("timers/updateTimer", this.timer);
      } else {
        await this.$store.dispatch("timers/create", this.timer);
      }

      if (this.timerErrors) {
        await this.showAlert("error", "Error saving timer!");
      } else {
        await this.showAlert("success", "Timer Saved!");
      }
    },

    setDefault() {
      if (this.$route.path !== "/") this.$router.replace("/");
      this.$store.commit("timers/SET_TIMER", defaultTimer);
    },
  },

  computed: {
    ...mapGetters({
      timer: "timers/timer",
      dimensions: "timers/dimensions",
      timerErrors: "timers/errors",
      currentUser: "auth/currentUser",
      binding: "bindings/binding",
    }),

    htmlCode() {
      return `<img\n    src="${BASE}/api-rest/v1/timers/${this.timer.id}/track?email={{ email }}"\n    alt="emailtool.io"\n/>`;
    },
    toggleBtnClasses() {
      return this.timer &&
        this.timer.hasOwnProperty("id") &&
        this.timer.id.length > 0
        ? "text-sm font-bold"
        : "text-sm sm:opacity-50 cursor-not-allowed";
    },
    currentTimer() {
      return this.timer &&
        this.timer.hasOwnProperty("id") &&
        this.timer.id.length > 0
        ? this.timer
        : defaultTimer;
    },
    currentLayout() {
      return this.windowWidth > "640" ? "d-layout" : "m-layout";
    },
  },

  async created() {
    // init view variant
    this.view = "code";
    // init window width to determin layout
    this.windowWidth = window.innerWidth;

    this.$route.params.timerId
      ? await this.$store.dispatch(
          "timers/getTimer",
          this.$route.params.timerId
        )
      : await this.$store.commit("timers/SET_TIMER", defaultTimer);
  },

  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$store.dispatch("timers/reset");
  },
};
</script>
