import { DateTime } from "luxon";

export const fontSizes = populateSizes();

export const defaultTimer = {
  id: "",
  name: "Timer name",
  quantize: true,
  optimize: true,
  date_end: setDeafaultTimerDate(),
  date_end_timezone: "UTC",
  type: 1,
  cd_day: 0,
  cd_hour: 0,
  cd_minute: 0,
  cd_second: 0,
  after_expiration: 0,
  expired_mes: null,
  labels_lang: "en",
  custom_labels: false,
  custom_label_days: null,
  custom_label_hours: null,
  custom_label_minutes: null,
  custom_label_seconds: null,
  template: 1,
  digits_font_size: 73,
  digits_font_style: "OpenSans-Bold",
  digits_color: "#ffffff",
  labels_font_size: 27,
  labels_font_style: "OpenSans-Regular",
  labels_color: "#ffffff",
  accent_font_size: 53,
  accent_font_style: "OpenSans-Bold",
  accent_color: "#ffffff",
  accent: true,
  bg_type: 0,
  bg_color: "#24AD69",
  bg_url: null,
  bg_uid: "",
  accent: true,
  days: true,
  hours: true,
  minutes: true,
  seconds: true
};

export const fonts = [
  {
    fontName: "Arial",
    fontFamily: "Arial",
    fontWeight: "400",
    imgUrl: "/fonts_gif/ArialUnicode.gif",
    value: "ArialUnicode",
  },
  {
    fontName: "Arial Black",
    fontFamily: "Arial",
    fontWeight: "900",
    imgUrl: "/fonts_gif/Arial_Black.gif",
    value: "Arial_Black",
  },
  {
    fontName: "Arsenal Bold",
    fontFamily: "Arsenal",
    fontWeight: "700",
    imgUrl: "/fonts_gif/Arsenal-Bold.gif",
    value: "Arsenal-Bold",
  },
  {
    fontName: "Arsenal Regular",
    fontFamily: "Arsenal",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Arsenal-Regular.gif",
    value: "Arsenal-Regular",
  },
  {
    fontName: "AvenirNext",
    fontFamily: "Avenir Next",
    fontWeight: "",
    imgUrl: "/fonts_gif/AvenirNext.gif",
    value: "AvenirNext",
  },
  {
    fontName: "BebasNeue",
    fontFamily: "Bebas Neue",
    fontWeight: "",
    imgUrl: "/fonts_gif/BebasNeue.gif",
    value: "BebasNeue-Regular",
  },
  {
    fontName: "Codystar",
    fontFamily: "Codystar",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Codystar-Regular.gif",
    value: "Codystar-Regular",
  },
  {
    fontName: "Comic Sans MS",
    fontFamily: "Comic Sans MS",
    fontWeight: "400",
    imgUrl: "/fonts_gif/ComicSansMS.gif",
    value: "ComicSansMS",
  },
  {
    fontName: "Courier New",
    fontFamily: "Courier New",
    fontWeight: "400",
    imgUrl: "/fonts_gif/CourierNew.gif",
    value: "CourierNew",
  },
  {
    fontName: "Droid Serif Bold",
    fontFamily: "Droid Serif Bold",
    fontWeight: 700,
    imgUrl: "/fonts_gif/DroidSerifBold.gif",
    value: "DroidSerif-Bold",
  },
  {
    fontName: "Droid Serif Regular",
    fontFamily: "Droid Serif Regular",
    fontWeight: 400,
    imgUrl: "/fonts_gif/DroidSerif-Regular.gif",
    value: "DroidSerif-Regular",
  },
  {
    fontName: "Georgia",
    fontFamily: "",
    fontWeight: "",
    imgUrl: "/fonts_gif/Georgia.gif",
    value: "Georgia",
  },
  {
    fontName: "Helvetica",
    fontFamily: "Helvetica",
    fontWeight: "",
    imgUrl: "/fonts_gif/Helvetica.gif",
    value: "Helvetica",
  },
  {
    // replace
    fontName: "Helvetica-Narrow",
    fontFamily: "Helvetica Narrow",
    fontWeight: "",
    imgUrl: "/fonts_gif/HelveticaNw.gif",
    value: "HelveticaNw",
  },
  {
    fontName: "Helvetica-Narrow Bold",
    fontFamily: "Helvetica Narrow Bold",
    fontWeight: "",
    imgUrl: "/fonts_gif/HelveticaNarrowBold.gif",
    value: "HelveticaNarrowBold",
  },
  {
    fontName: "Impact",
    fontFamily: "Impact",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Impact.gif",
    value: "Impact",
  },
  {
    fontName: "Inter Black",
    fontFamily: "Inter",
    fontWeight: "900",
    imgUrl: "/fonts_gif/Inter-Black.gif",
    value: "Inter-Black",
  },
  {
    fontName: "Inter Bold",
    fontFamily: "Inter",
    fontWeight: "700",
    imgUrl: "/fonts_gif/Inter-Bold.gif",
    value: "Inter-Bold",
  },
  {
    fontName: "Inter Light",
    fontFamily: "Inter",
    fontWeight: "300",
    imgUrl: "/fonts_gif/Inter-Light.gif",
    value: "Inter-Light",
  },
  {
    fontName: "Inter Regular",
    fontFamily: "Inter",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Inter-Regular.gif",
    value: "Inter-Regular",
  },
  {
    fontName: "Kaushan Script",
    fontFamily: "Kaushan Script",
    fontWeight: "400",
    imgUrl: "/fonts_gif/KaushanScript.gif",
    value: "KaushanScript",
  },
  {
    fontName: "Lucida-grande",
    fontFamily: "Lucida Grande",
    fontWeight: "",
    imgUrl: "/fonts_gif/Lucida-grande.gif",
    value: "LucidaGrande",
  },
  {
    fontName: "Marketing Script",
    fontFamily: "Marketing Script",
    fontWeight: "",
    imgUrl: "/fonts_gif/MarketingScript.gif",
    value: "MarketingScript",
  },
  // {
  //   // won't work
  //   fontName: "MS Serif",
  //   fontFamily: "MS Serif",
  //   fontWeight: "400",
  //   imgUrl: "/fonts_gif/PTSerif.gif",
  //   value: "PTSerif-Regular",
  // },
  {
    fontName: "Myriad Pro Bold",
    fontFamily: "Myriad Pro Bold",
    fontWeight: "",
    imgUrl: "/fonts_gif/Myriad-Pro-Bold.gif",
    value: "Myriad-Pro-Bold",
  },
  {
    fontName: "MyriadApple",
    fontFamily: "Myriad Apple",
    fontWeight: "",
    imgUrl: "/fonts_gif/MyriadApple.gif",
    value: "MyriadApple",
  },
  {
    fontName: "MyriadPro Regular",
    fontFamily: "Myriad Pro Regular",
    fontWeight: "",
    imgUrl: "/fonts_gif/MyriadPro-Regular.gif",
    value: "MyriadPro-Regular",
  },
  {
    fontName: "MyriadPro Semibold",
    fontFamily: "Myriad Pro Semibold",
    fontWeight: "",
    imgUrl: "/fonts_gif/MyriadPro-Semibold.gif",
    value: "MyriadPro-Semibold",
  },
  {
    fontName: "Oleo Script",
    fontFamily: "Oleo Script",
    fontWeight: "400",
    imgUrl: "/fonts_gif/OleoScript-Regular.gif",
    value: "OleoScript-Regular",
  },
  {
    fontName: "OpenSans-Bold",
    fontFamily: "Open Sans",
    fontWeight: "700",
    imgUrl: "/fonts_gif/OpenSans-Bold.gif",
    value: "OpenSans-Bold",
  },
  {
    fontName: "OpenSans-ExtraBold",
    fontFamily: "Open Sans",
    fontWeight: "800",
    imgUrl: "/fonts_gif/OpenSans-ExtraBold.gif",
    value: "OpenSans-ExtraBold",
  },
  {
    fontName: "OpenSans-Light",
    fontFamily: "Open Sans",
    fontWeight: "300",
    imgUrl: "/fonts_gif/OpenSans-Light.gif",
    value: "OpenSans-Light",
  },
  {
    fontName: "OpenSans-Regular",
    fontFamily: "Open Sans",
    fontWeight: "400",
    imgUrl: "/fonts_gif/OpenSans-Regular.gif",
    value: "OpenSans-Regular",
  },
  {
    fontName: "OpenSans Semibold",
    fontFamily: "Open Sans",
    fontWeight: "700",
    imgUrl: "/fonts_gif/OpenSans-Semibold.gif",
    value: "OpenSans-Semibold",
  },
  {
    fontName: "PT Sans Bold",
    fontFamily: "PT Sans",
    fontWeight: "700",
    imgUrl: "/fonts_gif/PT_Sans-Web-Bold.gif",
    value: "PTSans-Bold",
  },
  {
    fontName: "PT Sans Regular",
    fontFamily: "PT Sans",
    fontWeight: "400",
    imgUrl: "/fonts_gif/PT_Sans-Web-Regular.gif",
    value: "PTSans-Regular",
  },
  {
    fontName: "Roboto-Black",
    fontFamily: "Roboto",
    fontWeight: "900",
    imgUrl: "/fonts_gif/Roboto-Black.gif",
    value: "Roboto-Black",
  },
  {
    fontName: "Roboto-Bold",
    fontFamily: "Roboto",
    fontWeight: "700",
    imgUrl: "/fonts_gif/Roboto-Bold.gif",
    value: "Roboto-Bold",
  },
  {
    fontName: "Roboto-Light",
    fontFamily: "Roboto",
    fontWeight: "300",
    imgUrl: "/fonts_gif/Roboto-Light.gif",
    value: "Roboto-Light",
  },
  {
    fontName: "Roboto-Medium",
    fontFamily: "Roboto",
    fontWeight: "500",
    imgUrl: "/fonts_gif/Roboto-Medium.gif",
    value: "Roboto-Medium",
  },
  {
    fontName: "Roboto-Regular",
    fontFamily: "Roboto",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Roboto-Regular.gif",
    value: "Roboto-Regular",
  },
  {
    fontName: "Roboto-Thin",
    fontFamily: "Roboto",
    fontWeight: "300",
    imgUrl: "/fonts_gif/Roboto-Thin.gif",
    value: "Roboto-Thin",
  },
  {
    fontName: "RobotoCondensed-Bold",
    fontFamily: "Roboto Condensed",
    fontWeight: "700",
    imgUrl: "/fonts_gif/RobotoCondensed-Bold.gif",
    value: "RobotoCondensed-Bold",
  },
  {
    fontName: "RobotoCondensed-Light",
    fontFamily: "Roboto Condensed",
    fontWeight: "300",
    imgUrl: "/fonts_gif/RobotoCondensed-Light.gif",
    value: "RobotoCondensed-Light",
  },
  {
    fontName: "RobotoCondensed-Regular",
    fontFamily: "Roboto Condensed",
    fontWeight: "400",
    imgUrl: "/fonts_gif/RobotoCondensed-Regular.gif",
    value: "RobotoCondensed-Regular",
  },
  {
    fontName: "SpicyRice",
    fontFamily: "Spicy Rice",
    fontWeight: "400",
    imgUrl: "/fonts_gif/SpicyRice.gif",
    value: "SpicyRice-Regular",
  },
  {
    fontName: "Times New Roman",
    fontFamily: "Times New Roman",
    fontWeight: 400,
    imgUrl: "/fonts_gif/TimesNewRoman.gif",
    value: "TimesNewRoman",
  },
  {
    fontName: "Trebuchet MS",
    fontFamily: "Trebuchet MS",
    fontWeight: "400",
    imgUrl: "/fonts_gif/TrebuchetMS.gif",
    value: "TrebuchetMS",
  },
  {
    fontName: "Verdana",
    fontFamily: "Verdana",
    fontWeight: "400",
    imgUrl: "/fonts_gif/Verdana.gif",
    value: "Verdana",
  },
];

export const expireActions = [
  { id: 0, value: "Show zeros" },
  { id: 1, value: "Show expiration message" },
  { id: 2, value: "Hide timer" },
];

export const bgTypes = [
  { id: 0, value: "Color" },
  { id: 1, value: "Transparent" },
  { id: 2, value: "Image" },
];

export const timezones = [
  { value: "Africa/Abidjan" },
  { value: "Africa/Accra" },
  { value: "Africa/Addis_Ababa" },
  { value: "Africa/Algiers" },
  { value: "Africa/Asmara" },
  { value: "Africa/Bamako" },
  { value: "Africa/Bangui" },
  { value: "Africa/Banjul" },
  { value: "Africa/Bissau" },
  { value: "Africa/Blantyre" },
  { value: "Africa/Brazzaville" },
  { value: "Africa/Bujumbura" },
  { value: "Africa/Cairo" },
  { value: "Africa/Casablanca" },
  { value: "Africa/Ceuta" },
  { value: "Africa/Conakry" },
  { value: "Africa/Dakar" },
  { value: "Africa/Dar_es_Salaam" },
  { value: "Africa/Djibouti" },
  { value: "Africa/Douala" },
  { value: "Africa/El_Aaiun" },
  { value: "Africa/Freetown" },
  { value: "Africa/Gaborone" },
  { value: "Africa/Harare" },
  { value: "Africa/Johannesburg" },
  { value: "Africa/Juba" },
  { value: "Africa/Kampala" },
  { value: "Africa/Khartoum" },
  { value: "Africa/Kigali" },
  { value: "Africa/Kinshasa" },
  { value: "Africa/Lagos" },
  { value: "Africa/Libreville" },
  { value: "Africa/Lome" },
  { value: "Africa/Luanda" },
  { value: "Africa/Lubumbashi" },
  { value: "Africa/Lusaka" },
  { value: "Africa/Malabo" },
  { value: "Africa/Maputo" },
  { value: "Africa/Maseru" },
  { value: "Africa/Mbabane" },
  { value: "Africa/Mogadishu" },
  { value: "Africa/Monrovia" },
  { value: "Africa/Nairobi" },
  { value: "Africa/Ndjamena" },
  { value: "Africa/Niamey" },
  { value: "Africa/Nouakchott" },
  { value: "Africa/Ouagadougou" },
  { value: "Africa/Porto-Novo" },
  { value: "Africa/Sao_Tome" },
  { value: "Africa/Tripoli" },
  { value: "Africa/Tunis" },
  { value: "Africa/Windhoek" },
  { value: "America/Adak" },
  { value: "America/Anchorage" },
  { value: "America/Anguilla" },
  { value: "America/Antigua" },
  { value: "America/Araguaina" },
  { value: "America/Argentina/Buenos_Aires" },
  { value: "America/Argentina/Catamarca" },
  { value: "America/Argentina/Cordoba" },
  { value: "America/Argentina/Jujuy" },
  { value: "America/Argentina/La_Rioja" },
  { value: "America/Argentina/Mendoza" },
  { value: "America/Argentina/Rio_Gallegos" },
  { value: "America/Argentina/Salta" },
  { value: "America/Argentina/San_Juan" },
  { value: "America/Argentina/San_Luis" },
  { value: "America/Argentina/Tucuman" },
  { value: "America/Argentina/Ushuaia" },
  { value: "America/Aruba" },
  { value: "America/Asuncion" },
  { value: "America/Atikokan" },
  { value: "America/Bahia" },
  { value: "America/Bahia_Banderas" },
  { value: "America/Barbados" },
  { value: "America/Belem" },
  { value: "America/Belize" },
  { value: "America/Blanc-Sablon" },
  { value: "America/Boa_Vista" },
  { value: "America/Bogota" },
  { value: "America/Boise" },
  { value: "America/Cambridge_Bay" },
  { value: "America/Campo_Grande" },
  { value: "America/Cancun" },
  { value: "America/Caracas" },
  { value: "America/Cayenne" },
  { value: "America/Cayman" },
  { value: "America/Chicago" },
  { value: "America/Chihuahua" },
  { value: "America/Costa_Rica" },
  { value: "America/Creston" },
  { value: "America/Cuiaba" },
  { value: "America/Curacao" },
  { value: "America/Danmarkshavn" },
  { value: "America/Dawson" },
  { value: "America/Dawson_Creek" },
  { value: "America/Denver" },
  { value: "America/Detroit" },
  { value: "America/Dominica" },
  { value: "America/Edmonton" },
  { value: "America/Eirunepe" },
  { value: "America/El_Salvador" },
  { value: "America/Fortaleza" },
  { value: "America/Glace_Bay" },
  { value: "America/Godthab" },
  { value: "America/Goose_Bay" },
  { value: "America/Grand_Turk" },
  { value: "America/Grenada" },
  { value: "America/Guadeloupe" },
  { value: "America/Guatemala" },
  { value: "America/Guayaquil" },
  { value: "America/Guyana" },
  { value: "America/Halifax" },
  { value: "America/Havana" },
  { value: "America/Hermosillo" },
  { value: "America/Indiana/Indianapolis" },
  { value: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City" },
  { value: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac" },
  { value: "America/Inuvik" },
  { value: "America/Iqaluit" },
  { value: "America/Jamaica" },
  { value: "America/Juneau" },
  { value: "America/Kentucky/Louisville" },
  { value: "America/Kentucky/Monticello" },
  { value: "America/Kralendijk" },
  { value: "America/La_Paz" },
  { value: "America/Lima" },
  { value: "America/Los_Angeles" },
  { value: "America/Lower_Princes" },
  { value: "America/Maceio" },
  { value: "America/Managua" },
  { value: "America/Manaus" },
  { value: "America/Marigot" },
  { value: "America/Martinique" },
  { value: "America/Matamoros" },
  { value: "America/Mazatlan" },
  { value: "America/Menominee" },
  { value: "America/Merida" },
  { value: "America/Metlakatla" },
  { value: "America/Mexico_City" },
  { value: "America/Miquelon" },
  { value: "America/Moncton" },
  { value: "America/Monterrey" },
  { value: "America/Montevideo" },
  { value: "America/Montserrat" },
  { value: "America/Nassau" },
  { value: "America/New_York" },
  { value: "America/Nipigon" },
  { value: "America/Nome" },
  { value: "America/Noronha" },
  { value: "America/North_Dakota/Beulah" },
  { value: "America/North_Dakota/Center" },
  { value: "America/North_Dakota/New_Salem" },
  { value: "America/Ojinaga" },
  { value: "America/Panama" },
  { value: "America/Pangnirtung" },
  { value: "America/Paramaribo" },
  { value: "America/Phoenix" },
  { value: "America/Port-au-Prince" },
  { value: "America/Port_of_Spain" },
  { value: "America/Porto_Velho" },
  { value: "America/Puerto_Rico" },
  { value: "America/Rainy_River" },
  { value: "America/Rankin_Inlet" },
  { value: "America/Recife" },
  { value: "America/Regina" },
  { value: "America/Resolute" },
  { value: "America/Rio_Branco" },
  { value: "America/Santa_Isabel" },
  { value: "America/Santarem" },
  { value: "America/Santiago" },
  { value: "America/Santo_Domingo" },
  { value: "America/Sao_Paulo" },
  { value: "America/Scoresbysund" },
  { value: "America/Sitka" },
  { value: "America/St_Barthelemy" },
  { value: "America/St_Johns" },
  { value: "America/St_Kitts" },
  { value: "America/St_Lucia" },
  { value: "America/St_Thomas" },
  { value: "America/St_Vincent" },
  { value: "America/Swift_Current" },
  { value: "America/Tegucigalpa" },
  { value: "America/Thule" },
  { value: "America/Thunder_Bay" },
  { value: "America/Tijuana" },
  { value: "America/Toronto" },
  { value: "America/Tortola" },
  { value: "America/Vancouver" },
  { value: "America/Whitehorse" },
  { value: "America/Winnipeg" },
  { value: "America/Yakutat" },
  { value: "America/Yellowknife" },
  { value: "Antarctica/Casey" },
  { value: "Antarctica/Davis" },
  { value: "Antarctica/DumontDUrville" },
  { value: "Antarctica/Macquarie" },
  { value: "Antarctica/Mawson" },
  { value: "Antarctica/McMurdo" },
  { value: "Antarctica/Palmer" },
  { value: "Antarctica/Rothera" },
  { value: "Antarctica/Syowa" },
  { value: "Antarctica/Troll" },
  { value: "Antarctica/Vostok" },
  { value: "Arctic/Longyearbyen" },
  { value: "Asia/Aden" },
  { value: "Asia/Almaty" },
  { value: "Asia/Amman" },
  { value: "Asia/Anadyr" },
  { value: "Asia/Aqtau" },
  { value: "Asia/Aqtobe" },
  { value: "Asia/Ashgabat" },
  { value: "Asia/Baghdad" },
  { value: "Asia/Bahrain" },
  { value: "Asia/Baku" },
  { value: "Asia/Bangkok" },
  { value: "Asia/Beirut" },
  { value: "Asia/Bishkek" },
  { value: "Asia/Brunei" },
  { value: "Asia/Choibalsan" },
  { value: "Asia/Chongqing" },
  { value: "Asia/Colombo" },
  { value: "Asia/Damascus" },
  { value: "Asia/Dhaka" },
  { value: "Asia/Dili" },
  { value: "Asia/Dubai" },
  { value: "Asia/Dushanbe" },
  { value: "Asia/Gaza" },
  { value: "Asia/Harbin" },
  { value: "Asia/Hebron" },
  { value: "Asia/Ho_Chi_Minh" },
  { value: "Asia/Hong_Kong" },
  { value: "Asia/Hovd" },
  { value: "Asia/Irkutsk" },
  { value: "Asia/Jakarta" },
  { value: "Asia/Jayapura" },
  { value: "Asia/Jerusalem" },
  { value: "Asia/Kabul" },
  { value: "Asia/Kamchatka" },
  { value: "Asia/Karachi" },
  { value: "Asia/Kashgar" },
  { value: "Asia/Kathmandu" },
  { value: "Asia/Khandyga" },
  { value: "Asia/Kolkata" },
  { value: "Asia/Krasnoyarsk" },
  { value: "Asia/Kuala_Lumpur" },
  { value: "Asia/Kuching" },
  { value: "Asia/Kuwait" },
  { value: "Asia/Macau" },
  { value: "Asia/Magadan" },
  { value: "Asia/Makassar" },
  { value: "Asia/Manila" },
  { value: "Asia/Muscat" },
  { value: "Asia/Nicosia" },
  { value: "Asia/Novokuznetsk" },
  { value: "Asia/Novosibirsk" },
  { value: "Asia/Omsk" },
  { value: "Asia/Oral" },
  { value: "Asia/Phnom_Penh" },
  { value: "Asia/Pontianak" },
  { value: "Asia/Pyongyang" },
  { value: "Asia/Qatar" },
  { value: "Asia/Qyzylorda" },
  { value: "Asia/Rangoon" },
  { value: "Asia/Riyadh" },
  { value: "Asia/Sakhalin" },
  { value: "Asia/Samarkand" },
  { value: "Asia/Seoul" },
  { value: "Asia/Shanghai" },
  { value: "Asia/Singapore" },
  { value: "Asia/Taipei" },
  { value: "Asia/Tashkent" },
  { value: "Asia/Tbilisi" },
  { value: "Asia/Tehran" },
  { value: "Asia/Thimphu" },
  { value: "Asia/Tokyo" },
  { value: "Asia/Ulaanbaatar" },
  { value: "Asia/Urumqi" },
  { value: "Asia/Ust-Nera" },
  { value: "Asia/Vientiane" },
  { value: "Asia/Vladivostok" },
  { value: "Asia/Yakutsk" },
  { value: "Asia/Yekaterinburg" },
  { value: "Asia/Yerevan" },
  { value: "Atlantic/Azores" },
  { value: "Atlantic/Bermuda" },
  { value: "Atlantic/Canary" },
  { value: "Atlantic/Cape_Verde" },
  { value: "Atlantic/Faroe" },
  { value: "Atlantic/Madeira" },
  { value: "Atlantic/Reykjavik" },
  { value: "Atlantic/South_Georgia" },
  { value: "Atlantic/St_Helena" },
  { value: "Atlantic/Stanley" },
  { value: "Australia/Adelaide" },
  { value: "Australia/Brisbane" },
  { value: "Australia/Broken_Hill" },
  { value: "Australia/Currie" },
  { value: "Australia/Darwin" },
  { value: "Australia/Eucla" },
  { value: "Australia/Hobart" },
  { value: "Australia/Lindeman" },
  { value: "Australia/Lord_Howe" },
  { value: "Australia/Melbourne" },
  { value: "Australia/Perth" },
  { value: "Australia/Sydney" },
  { value: "Europe/Amsterdam" },
  { value: "Europe/Andorra" },
  { value: "Europe/Athens" },
  { value: "Europe/Belgrade" },
  { value: "Europe/Berlin" },
  { value: "Europe/Bratislava" },
  { value: "Europe/Brussels" },
  { value: "Europe/Bucharest" },
  { value: "Europe/Budapest" },
  { value: "Europe/Busingen" },
  { value: "Europe/Chisinau" },
  { value: "Europe/Copenhagen" },
  { value: "Europe/Dublin" },
  { value: "Europe/Gibraltar" },
  { value: "Europe/Guernsey" },
  { value: "Europe/Helsinki" },
  { value: "Europe/Isle_of_Man" },
  { value: "Europe/Istanbul" },
  { value: "Europe/Jersey" },
  { value: "Europe/Kaliningrad" },
  { value: "Europe/Kiev" },
  { value: "Europe/Lisbon" },
  { value: "Europe/Ljubljana" },
  { value: "Europe/London" },
  { value: "Europe/Luxembourg" },
  { value: "Europe/Madrid" },
  { value: "Europe/Malta" },
  { value: "Europe/Mariehamn" },
  { value: "Europe/Minsk" },
  { value: "Europe/Monaco" },
  { value: "Europe/Moscow" },
  { value: "Europe/Oslo" },
  { value: "Europe/Paris" },
  { value: "Europe/Podgorica" },
  { value: "Europe/Prague" },
  { value: "Europe/Riga" },
  { value: "Europe/Rome" },
  { value: "Europe/Samara" },
  { value: "Europe/San_Marino" },
  { value: "Europe/Sarajevo" },
  { value: "Europe/Simferopol" },
  { value: "Europe/Skopje" },
  { value: "Europe/Sofia" },
  { value: "Europe/Stockholm" },
  { value: "Europe/Tallinn" },
  { value: "Europe/Tirane" },
  { value: "Europe/Uzhgorod" },
  { value: "Europe/Vaduz" },
  { value: "Europe/Vatican" },
  { value: "Europe/Vienna" },
  { value: "Europe/Vilnius" },
  { value: "Europe/Volgograd" },
  { value: "Europe/Warsaw" },
  { value: "Europe/Zagreb" },
  { value: "Europe/Zaporozhye" },
  { value: "Europe/Zurich" },
  { value: "GMT" },
  { value: "UTC" },
  { value: "Indian/Antananarivo" },
  { value: "Indian/Chagos" },
  { value: "Indian/Christmas" },
  { value: "Indian/Cocos" },
  { value: "Indian/Comoro" },
  { value: "Indian/Kerguelen" },
  { value: "Indian/Mahe" },
  { value: "Indian/Maldives" },
  { value: "Indian/Mauritius" },
  { value: "Indian/Mayotte" },
  { value: "Indian/Reunion" },
  { value: "Pacific/Apia" },
  { value: "Pacific/Auckland" },
  { value: "Pacific/Chatham" },
  { value: "Pacific/Chuuk" },
  { value: "Pacific/Easter" },
  { value: "Pacific/Efate" },
  { value: "Pacific/Enderbury" },
  { value: "Pacific/Fakaofo" },
  { value: "Pacific/Fiji" },
  { value: "Pacific/Funafuti" },
  { value: "Pacific/Galapagos" },
  { value: "Pacific/Gambier" },
  { value: "Pacific/Guadalcanal" },
  { value: "Pacific/Guam" },
  { value: "Pacific/Honolulu" },
  { value: "Pacific/Johnston" },
  { value: "Pacific/Kiritimati" },
  { value: "Pacific/Kosrae" },
  { value: "Pacific/Kwajalein" },
  { value: "Pacific/Majuro" },
  { value: "Pacific/Marquesas" },
  { value: "Pacific/Midway" },
  { value: "Pacific/Nauru" },
  { value: "Pacific/Niue" },
  { value: "Pacific/Norfolk" },
  { value: "Pacific/Noumea" },
  { value: "Pacific/Pago_Pago" },
  { value: "Pacific/Palau" },
  { value: "Pacific/Pitcairn" },
  { value: "Pacific/Pohnpei" },
  { value: "Pacific/Port_Moresby" },
  { value: "Pacific/Rarotonga" },
  { value: "Pacific/Saipan" },
  { value: "Pacific/Tahiti" },
  { value: "Pacific/Tarawa" },
  { value: "Pacific/Tongatapu" },
  { value: "Pacific/Wake" },
  { value: "Pacific/Wallis" },
];

function populateSizes() {
  let arr = [];
  for (let i = 12; i < 80; i++) {
    arr.push({ value: i });
  }
  return arr;
}

function setDeafaultTimerDate() {
  const currentTime = new DateTime.now();
  const tommorowDT = currentTime.plus({ days: 1 });
  // const offset = tommorowDT.offset - DateTime.now().setZone("UTC").offset;
  tommorowDT.setZone("UTC");

  return tommorowDT.toISO();
}
