var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1 sm:mx-2",style:(_vm.backgroundColor)},[(
      _vm.distance > 0 ||
        (_vm.distance == 0 &&
          _vm.timer.after_expiration === 0 &&
          _vm.timer.expired_mes == null)
    )?_c('div',{staticClass:"flex flex-row flex-nowrap h-28 md:h-36 gap-2 items-center justify-center transform scale-75 sm:scale-100",style:(("color: " + (_vm.timer.text_color)))},[(_vm.timer.days)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{style:(_vm.digitStyles)},[_vm._v(_vm._s(_vm.days))]),(_vm.timer)?_c('span',{staticClass:"text-base",style:(_vm.labelStyles)},[_vm._v(" "+_vm._s(_vm.labels.days)+" ")]):_vm._e()]):_vm._e(),(_vm.timer.days && _vm.timer.accent)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"text-xl",style:(_vm.accentStyle)},[_vm._v(":")]),(_vm.timer)?_c('span',[_vm._v("​")]):_vm._e()]):_vm._e(),(_vm.timer.hours)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{style:(_vm.digitStyles)},[_vm._v(_vm._s(_vm.hours))]),(_vm.timer)?_c('span',{staticClass:"text-base",style:(_vm.labelStyles)},[_vm._v(" "+_vm._s(_vm.labels.hours)+" ")]):_vm._e()]):_vm._e(),(_vm.timer.hours && _vm.timer.accent)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"text-xl",style:(_vm.accentStyle)},[_vm._v(":")]),(_vm.timer)?_c('span',[_vm._v("​")]):_vm._e()]):_vm._e(),(_vm.timer.minutes)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{style:(_vm.digitStyles)},[_vm._v(_vm._s(_vm.minutes))]),(_vm.timer)?_c('span',{staticClass:"text-base",style:(_vm.labelStyles)},[_vm._v(" "+_vm._s(_vm.labels.minutes)+" ")]):_vm._e()]):_vm._e(),(_vm.timer.minutes && _vm.timer.seconds && _vm.timer.accent)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"text-xl",style:(_vm.accentStyle)},[_vm._v(":")]),(_vm.timer)?_c('span',[_vm._v("​")]):_vm._e()]):_vm._e(),(_vm.timer.seconds)?_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{style:(_vm.digitStyles)},[_vm._v(_vm._s(_vm.seconds))]),(_vm.timer)?_c('span',{staticClass:"text-base",style:(_vm.labelStyles)},[_vm._v(" "+_vm._s(_vm.labels.seconds)+" ")]):_vm._e()]):_vm._e()]):(
      _vm.distance == 0 &&
        _vm.timer.after_expiration === 1 &&
        _vm.timer.expired_mes &&
        _vm.timer.expired_mes.length > 0
    )?_c('div',{staticClass:"flex flex-row flex-nowrap h-28 md:h-36 gap-4 items-center justify-center"},[_c('div',{},[_vm._v(" "+_vm._s(_vm.timer.expired_mes)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }