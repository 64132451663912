<template>
  <div class="h-7 w-10/12">
    <input
      type="text"
      :style="{ backgroundColor: value, borderColor: value }"
      class="z-10 text-black text-sm pl-1 border-2 rounded focus:outline-none w-full"
      :value="value"
      @focus="showPicker()"
      @input="handleChange($event)"
      @change="handleChange($event)"
    />
    <input
      type="color"
      class="absolute h-0 opacity-0"
      :style="{ backgroundColor: value }"
      ref="inputColor"
      :value="value"
      @input="handleChange($event)"
      @change="handleChange($event)"
    />
  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    showPicker() {
      this.$refs.inputColor.click();
    },
    // handleInput(event) {
    //   this.$emit("input", event.target.value);
    // },
    handleChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>
