<template>
  <div class="md:h-36 flex items-center">
    <img
      v-show="loaded"
      ref="gif"
      class="mx-auto"
      :src="imageSrc"
      @load="setLoaded()"
      alt="emailtool.io"
    />
    <spinner v-show="!loaded" color="text-green" size="md" />
  </div>
</template>

<script>
const BASE = process.env.VUE_APP_API_BASE;

import Spinner from "./core/Spinner.vue";

export default {
  name: "ImagePreview",
  components: {
    Spinner,
  },
  props: {
    timerId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      width: "",
      height: "",
    };
  },
  computed: {
    imageSrc() {
      return `${BASE}/api-rest/v1/timers/${this.timerId}/preview/`;
    },
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      this.getPreviewImgDimensions();
    },
    getPreviewImgDimensions() {
      this.width = this.$refs.gif.naturalWidth;
      this.height = this.$refs.gif.naturalHeight;
      this.$store.dispatch("timers/setDimensions", {
        width: this.width,
        height: this.height,
      });
    },
  },
};
</script>
