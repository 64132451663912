<template>
  <div class="mx-1 sm:mx-2" :style="backgroundColor">
    <div
      v-if="
        distance > 0 ||
          (distance == 0 &&
            timer.after_expiration === 0 &&
            timer.expired_mes == null)
      "
      class="flex flex-row flex-nowrap h-28 md:h-36 gap-2 items-center justify-center transform scale-75 sm:scale-100"
      :style="`color: ${timer.text_color}`"
    >
      <div v-if="timer.days" class="flex flex-col items-center">
        <span :style="digitStyles">{{ days }}</span>
        <span v-if="timer" class="text-base" :style="labelStyles">
          {{ labels.days }}
        </span>
      </div>
      <div v-if="timer.days && timer.accent" class="flex flex-col items-center">
        <span class="text-xl" :style="accentStyle">:</span>
        <span v-if="timer">&#8203;</span>
      </div>

      <div v-if="timer.hours" class="flex flex-col items-center">
        <span :style="digitStyles">{{ hours }}</span>
        <span v-if="timer" class="text-base" :style="labelStyles">
          {{ labels.hours }}
        </span>
      </div>
      <div
        v-if="timer.hours && timer.accent"
        class="flex flex-col items-center"
      >
        <span class="text-xl" :style="accentStyle">:</span>
        <span v-if="timer">&#8203;</span>
      </div>
      <div v-if="timer.minutes" class="flex flex-col items-center">
        <span :style="digitStyles">{{ minutes }}</span>
        <span v-if="timer" class="text-base" :style="labelStyles">
          {{ labels.minutes }}
        </span>
      </div>
      <div
        v-if="timer.minutes && timer.seconds && timer.accent"
        class="flex flex-col items-center"
      >
        <span class="text-xl" :style="accentStyle">:</span>
        <span v-if="timer">&#8203;</span>
      </div>
      <div v-if="timer.seconds" class="flex flex-col items-center">
        <span :style="digitStyles">{{ seconds }}</span>
        <span v-if="timer" class="text-base" :style="labelStyles">
          {{ labels.seconds }}
        </span>
      </div>
    </div>
    <div
      v-else-if="
        distance == 0 &&
          timer.after_expiration === 1 &&
          timer.expired_mes &&
          timer.expired_mes.length > 0
      "
      class="flex flex-row flex-nowrap h-28 md:h-36 gap-4 items-center justify-center"
    >
      <div class="">
        {{ timer.expired_mes }}
      </div>
    </div>
  </div>
</template>

<script>
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

import { DateTime } from "luxon";
import { fonts } from "@/services/editor";
import { mapGetters } from "vuex";

const toTens = function(i) {
  return (i < 10 ? "0" : "") + i;
};

export default {
  name: "TimerPreview",
  data() {
    return {
      countDown: null,
      currentTime: null,
      ticker: null,
    };
  },
  computed: {
    ...mapGetters({
      timer: "timers/timer",
    }),
    backgroundColor() {
      if (this.timer.bg_type === 0) {
        // Color
        return { backgroundColor: this.timer.bg_color };
      } else if (this.timer.bg_type === 1) {
        return {
          // Transparent
          backgroundImage: "url('/images/opaque.png')",
        };
      } else if (this.timer.bg_type === 2) {
        // Image
        return {};
      }
    },
    accentStyle() {
      return {
        color: this.timer.accent_color,
        fontSize: this.timer.accent_font_size + "px",
        fontFamily: this.timer.accent_font_style,
      };
    },
    digitStyles() {
      return {
        color: this.timer.digits_color,
        fontSize: this.timer.digits_font_size + "px",
        fontWeight: this.getDigitsFontByValue.fontWeight,
        fontFamily: this.getDigitsFontByValue.fontFamily,
        lineHeight: this.timer.digits_font_size + "px",
      };
    },
    labelStyles() {
      return {
        color: this.timer.labels_color,
        fontSize: this.timer.labels_font_size + "px",
        fontWeigt: this.getLabesFontByValue.fontWeight,
        fontFamily: this.getLabesFontByValue.fontFamily,
        lineHeight: this.timer.labels_font_size + "px",
      };
    },
    labels() {
      if (this.timer.custom_labels) {
        return {
          days: this.timer.custom_label_days || "DAYS",
          hours: this.timer.custom_label_hours || "HOURS",
          minutes: this.timer.custom_label_minutes || "MINUTES",
          seconds: this.timer.custom_label_seconds || "SECONDS",
        };
      } else {
        return {
          days: "DAYS",
          hours: "HOURS",
          minutes: "MINUTES",
          seconds: "SECONDS",
        };
      }
    },
    getDigitsFontByValue() {
      let timer = this.timer;
      return fonts.filter((font) => font.value === timer.digits_font_style)[0];
    },
    getLabesFontByValue() {
      let timer = this.timer;
      return fonts.filter((font) => font.value === timer.labels_font_style)[0];
    },
    evergreen() {
      let days = this.timer.cd_day || "0";
      let hours = this.timer.cd_hour || "0";
      let minutes = this.timer.cd_minute || "0";
      let seconds = this.timer.cd_second || "0";

      let dt = DateTime.now();
      let end_date = dt.plus({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
      return end_date;
    },
    days() {
      return toTens(Math.floor(this.distance / DAY));
    },
    hours() {
      return toTens(Math.floor((this.distance % DAY) / HOUR));
    },
    minutes() {
      return toTens(Math.floor((this.distance % HOUR) / MINUTE));
    },
    seconds() {
      return toTens(Math.floor((this.distance % MINUTE) / SECOND));
    },
    distance() {
      let now = this.currentTime;
      let date_end_zoned = DateTime.fromISO(this.timer.date_end).setZone(
        this.timer.date_end_timezone
      );

      let offset = date_end_zoned.offset - DateTime.now().setZone("UTC").offset;
      let distance_offset = date_end_zoned.plus({ minutes: offset }) - now;
      let evergreen_distance = this.evergreen - now;

      // return distance based on timer type
      if (this.timer.type == 1) {
        return distance_offset > 0 ? distance_offset : 0;
      } else if (this.timer.type == 2) {
        return evergreen_distance > 0 ? evergreen_distance : 0;
      }
    },
  },
  mounted() {
    this.currentTime = DateTime.now();

    this.ticker = setInterval(() => {
      this.currentTime = DateTime.now();
    }, 1000);
  },
  updated() {
    this.countDown = DateTime.fromISO(this.timer.date_end, {
      zone: this.timer.date_end_timezone,
    }).ts;
  },
  beforeDestroy() {
    clearInterval(this.ticker);
  },
};
</script>

<style scoped>
@import "../assets/css/fonts.css";
</style>
