<template>
  <div
    class="relative inline-block w-12 select-none transition duration-500 ease-in"
    @click="toggle()"
  >
    <input
      type="checkbox"
      class="absolute block  w-5 h-5 rounded-full  bg-white  appearance-none cursor-pointer"
      :class="[value ? 'bg-gray-200 right-0' : 'bg-green left-0']"
    />
    <label
      for="toggle"
      class="block mt-1 h-3  rounded-full cursor-pointer"
      :class="[value ? 'bg-green' : 'bg-gray-200']"
    >
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
