<template>
  <input
    type="datetime-local"
    :min="today.toFormat(`yyyy-MM-dd'T'HH:mm`)"
    :value="valueInUTC.toFormat(`yyyy-MM-dd'T'HH:mm`)"
    @input="onInput($event.target.value)"
    class="
      block w-full py-1 pl-2 transition duration-100 ease-in-out 
      text-sm text-black bg-gray-100 rounded focus:ring-gray-200 
      focus:outline-none ring-0 focus:ring-1 focus:ring-grey-300"
  />
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "DtInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", DateTime.fromISO(value, { zone: "UTC" }).toISO());
    },
  },
  computed: {
    today() {
      return DateTime.now().setZone("UTC");
    },
    valueInUTC() {
      return DateTime.fromISO(this.value, {
        zone: "UTC",
      });
    },
  },
};
</script>

<style scoped>
::-webkit-calendar-picker-indicator {
  margin-left: 0px;
}
</style>
