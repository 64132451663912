<template>
  <div class="min-w-full px-4 py-2 bg-white rounded-2xl">
    <editor-item title="General" v-model="activeTab">
      <div class="flex flex-col gap-4">
        <p class="text-xs font-bold">Countdown type</p>
        <!-- button group -->
        <div class="flex flex-row justify-start flex-wrap gap-2">
          <label class="btn" :class="[timer.type == 1 ? 'primary':'secondary']"> 
            <input type="radio" value="1" v-model="timer.type" class="appearance-none">Standard</input>
          </label>  
          <label class="btn" :class="[timer.type == 2 ? 'primary':'secondary']">
            <input type="radio" value="2" v-model="timer.type" class="appearance-none">Evergreen</input>
          </label> 
        </div>

        <div>
          <p class="text-xs font-bold">Timer name</p>
          <base-input type="text" v-model="timer.name" />
        </div>
        <div class="flex flex-row justify-between" v-if="timer.type == 1" >
          <span class="w-32">
            <p class="text-xs font-bold">End Date & Time</p>
            <dt-input style="height:32px" v-model="timer.date_end" />
            <!-- <date-picker 
              class="
                block w-full py-1 pl-2 transition duration-100 ease-in-out 
                text-sm text-black bg-gray-100 rounded focus:ring-gray-200 
                focus:outline-none ring-0 focus:ring-1 focus:ring-grey-300"
              type="datetime" 
              value-type="YYYY-MM-DDTHH:MM:SS.SSS" 
              v-model="timer.date_end" /> -->
          </span>
          <span class="w-32">
            <p class="text-xs font-bold">Time Zone</p>
            <base-select
              :options="timezones"
              v-model="timer.date_end_timezone"
              filtarable
            />
          </span>
        </div>
        <div class="flex flex-row justify-between gap-2" v-if="timer.type == 2">
          <span>
            <p class="text-xs font-bold">Days</p>
            <base-input
              type="number"
              placeholder=""
              v-model="timer.cd_day"
            />
          </span>
          <span>
            <p class="text-xs font-bold">Hours</p>
            <base-input
              type="number"
              placeholder=""
              v-model="timer.cd_hour"
            />
          </span>
          <span>
            <p class="text-xs font-bold">Minutes</p>
            <base-input
              type="number"
              placeholder=""
              v-model="timer.cd_minute"
            />
          </span>
          <span>
            <p class="text-xs font-bold">Seconds</p>
            <base-input
              type="number"
              placeholder=""
              v-model="timer.cd_second"
            />
          </span>
        </div>

        <div class="flex flex-row justify-start gap-8">
          <span>
            <p class="text-xs font-bold">Custom labels</p>
            <toggle v-model="timer.custom_labels" />
          </span>
        </div>

        <div v-if="timer.custom_labels" class="flex flex-row justify-between gap-6">
          <base-input
            type="text"
            placeholder="Days"
            v-model="timer.custom_label_days"
          />
          <base-input
            type="text"
            placeholder="Hours"
            v-model="timer.custom_label_hours"
          />
        </div>

        <div v-if="timer.custom_labels" class="flex flex-row justify-between gap-6">
          <base-input
            type="text"
            placeholder="Minutes"
            v-model="timer.custom_label_minutes"
          />
          <base-input
            type="text"
            placeholder="Seconds"
            v-model="timer.custom_label_seconds"
          />
        </div>
      </div>
    </editor-item>
    <editor-item title="Design" v-model="activeTab">
      <div class="flex flex-col gap-4">
        <div>
          <p class="text-xs font-bold mb-2">Template</p>
          <span class="flex flex-row justify-between">
            <img src="/images/selected_template.png" />
            <t-button primary>Change</t-button>
          </span>
        </div>

        <div>
          <p class="text-xs font-bold mb-2">Digit Font Style</p>
          <span class="flex flex-row justify-between gap-4">
            <span class="w-1/4">
              <base-select
                type="Number"
                :options="fontSizes"
                v-model="timer.digits_font_size"
              />
            </span>
            <base-select
              :options="fonts"
              v-model="timer.digits_font_style"
              filtarable
            />
          </span>
        </div>

        <div>
          <p class="text-xs font-bold mb-2">Labels font Style</p>
          <span class="flex flex-row justify-between gap-4">
            <span class="w-1/4">
              <base-select
                :options="fontSizes"
                v-model="timer.labels_font_size"
              />
            </span>
            <base-select
              :options="fonts"
              v-model="timer.labels_font_style"
              filtarable
            />
          </span>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-xs font-bold mb-2">After expiration action</p>
          <base-select :options="actions" v-model="timer.after_expiration" />
          <base-input type="text" v-model="timer.expired_mes" v-if="timer.after_expiration === 1" />
        </div>

        <div class="flex flex-row justify-start gap-8">
          <span class="flex flex-col">
            <p class="text-xs font-bold mb-2">Separator size</p>
            <base-select
              :options="fontSizes"
              v-model="timer.accent_font_size"
            />
          </span>
          <span class="flex flex-col">
            <p class="text-xs font-bold mb-2">Show separator</p>
            <toggle v-model="timer.accent" />
          </span>
        </div>

        <div class="flex flex-row justify-between gap-6 flex-wrap">
          <span>
            <p class="text-xs font-bold mb-2">Days</p>
            <toggle v-model="timer.days"
          /></span>
          <span>
            <p class="text-xs font-bold mb-2">Hours</p>
            <toggle v-model="timer.hours"/></span
          ><span>
            <p class="text-xs font-bold mb-2">Minutes</p>
            <toggle v-model="timer.minutes"/></span
          ><span>
            <p class="text-xs font-bold mb-2">Seconds</p>
            <toggle v-model="timer.seconds" />
          </span>
        </div>
      </div>
    </editor-item>
    <editor-item title="Colors & Background" v-model="activeTab">
      <!-- section content wrapper -->
      <div class="flex flex-col gap-4">
        <div class="flex flex-row justify-between gap-2 flex-wrap">
          <span class="flex flex-col w-5/12">
            <p class="text-xs font-bold mb-2">Accent color</p>
            <color-picker
              id="accent-color-picker"
              v-model="timer.accent_color"
            />
          </span>
          <span class="flex flex-col w-5/12">
            <p class="text-xs font-bold mb-2">Digits color</p>
            <color-picker
              id="digits-color-picker"
              v-model="timer.digits_color"
            />
          </span>

          <span class="flex flex-col w-5/12">
            <p class="text-xs font-bold mb-2">Labels color</p>
            <color-picker
              id="accent-color-picker"
              v-model="timer.labels_color"
            />
          </span>
          <span class="flex flex-col w-5/12">
            <p class="text-xs font-bold mb-2">Background</p>
            <color-picker id="digits-color-picker" v-model="timer.bg_color" />
          </span>
        </div>

        <div class="flex flex-col gap-4">
          <p class="text-xs font-bold">Background type</p>
          <base-select :options="bgTypes" v-model="timer.bg_type" />
            <input type="file" id="file" ref="fileInput" @change="handleFileUpload()" hidden />
          <t-button 
            primary 
            v-if="timer.bg_type === 2" 
            @click="handleLoadImageInput()">
            Choose image...
          </t-button>
          
        </div>
      </div>
      <!-- end of section content wrapper -->
    </editor-item>

    <editor-item
      title="Dynamic Links"
      :isEnabled="false"
      v-model="activeTab"
    ></editor-item>
    <editor-item
      title="Advanced options"
      :isEnabled="false"
      v-model="activeTab"
    ></editor-item>
  </div>
</template>

<script>
import EditorItem from "./EditorItem.vue";
import TButton from "./core/Button.vue";
import BaseInput from "./core/BaseInput.vue";
import ColorPicker from "./core/ColorPicker.vue";
import Toggle from "./core/Toggle.vue";
import BaseSelect from "./core/BaseSelect.vue";
import DtInput from "./core/DtInput.vue";

import axios from "axios";

import {
  fontSizes,
  fonts,
  expireActions,
  timezones,
  bgTypes,
} from "@/services/editor.js";
import { mapGetters } from 'vuex';

export default {
  name: "Editor",
  components: {
    EditorItem,
    TButton,
    BaseInput,
    ColorPicker,
    Toggle,
    BaseSelect,
    DtInput,
  },
  data() {
    return {
      activeTab: "General",
      timezones: timezones,
      fonts: fonts,
      fontSizes: fontSizes,
      actions: expireActions,
      bgTypes: bgTypes,
      selectedImage: null,
      selecedImageName: ""
    };
  },
  computed: {
    ...mapGetters({
      timer: 'timers/timer'
    })
  },
  methods: {
    handleLoadImageInput() {
      this.$refs.fileInput.click()
    },

    async handleFileUpload() {
      this.selecedImageName = await this.$refs.fileInput.files[0].name;
      this.selectedImage = await this.$refs.fileInput.files[0];

      console.log('>>>> 1st element in files array >>>> ', this.selectedImage);

      console.log('file >>', typeof this.selectedImage, this.selectedImage)

      let formData = new FormData();

      await formData.append('file', this.selectedImage, this.selecedImageName);
      await console.log('>> formData >> ', formData);

      await this.saveImage(formData);
    },

    saveImage(data) {
      axios.post('/api-rest/v1/content/', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        console.log(response.data)
        this.timer.bg_uid = response.data.id
      })
      .catch((error) => {
        console.log(error.message)
        return new Error(error.message)
      })
    },
  }
};
</script>

<style>
.btn {
  @apply block
  px-4 py-0.5
  text-sm font-medium tracking-wide
  text-center
  duration-100
  ease-in-out
  rounded;
}

.primary {
  @apply bg-green text-white hover:bg-green-600 focus:bg-green-700;
}

.secondary {
  @apply bg-gray-200 hover:bg-gray-400 focus:bg-gray-500;
}
</style>
