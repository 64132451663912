<template>
  <div class="alert-toast fixed bottom-0 right-0 m-2 w-64">
    <input
      type="checkbox"
      class="hidden"
      id="footertoast"
      @input="handleInput()"
    />

    <label
      class="close cursor-pointer flex items-center justify-around p-2 h-10 rounded-xl bg-white text-2xl px-2"
      :class="type == 'success' ? 'text-green' : 'text-red-600'"
      title="close"
      for="footertoast"
    >
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "success",
    },
  },
  methods: {
    handleInput() {
      this.$emit("on-alert-click", false);
    },
  },
};
</script>

<style scoped>
/*Toast open/load animation*/
.alert-toast {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Toast close animation*/
.alert-toast input:checked ~ * {
  -webkit-animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>
