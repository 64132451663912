<template>
  <div class="relative flex w-full" v-click-outside="close">
    <button
      class="
      w-full flex flex-row items-center justify-between px-2 py-1 text-sm bg-gray-100 rounded-md
      focus:outline-none ring-0 focus:ring-1 focus:ring-gray-300"
      @click="expanded = !expanded"
    >
      <span v-if="selected.hasOwnProperty('imgUrl')">
        <img :src="`${selected.imgUrl}`" />
      </span>
      <span v-else>{{ selected.value }}</span>
      <span>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          :fill="expanded ? '#24AD69' : '#BDC9D5'"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.42606 3.6573L0.111468 3.6573L3.76876 9.99192L7.42606 3.6573Z"
            fill="inherit"
          />
        </svg>
      </span>
    </button>

    <ul v-if="expanded" class="options-wrapper">
      <li v-if="filtarable" class="option">
        <input
          type="text"
          class="w-full px-2 rounded-md focus:outline-none ring-0 focus:ring-1 focus:ring-gray-300"
          v-model="search"
          @click="expanded = true"
        />
      </li>
      <li
        v-for="(option, key) in filteredOptions"
        :key="key"
        :id="`${'listbox-option-' + key}`"
        role="option"
        @click="clickHandler(option)"
        class="option"
      >
        <div
          v-if="option.hasOwnProperty('imgUrl')"
          class="h-6"
          style="background-position: left center; background-repeat: no-repeat; background-size: contain;"
          :style="{ 'background-image': `url('${option.imgUrl}')` }"
        ></div>
        <div v-else>
          {{ option.value }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  model: {
    prop: "value",
    event: "select",
  },
  props: {
    value: {
      type: String | Number,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    filtarable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      search: "",
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
    };
  },
  methods: {
    getOptionByValue() {
      return this.options.filter((option) => option.value == this.value);
    },
    clickHandler(option) {
      this.selected = option;
      option.hasOwnProperty("id")
        ? this.$emit("select", option.id)
        : this.$emit("select", option.value);
      this.close();
    },
    toggle() {
      this.expanded = !this.expanded;
    },
    close() {
      this.expanded = false;
    },
  },
  computed: {
    filteredOptions() {
      if (this.filtarable == true) {
        return this.options.filter(
          (option) =>
            option.value.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      } else {
        return this.options;
      }
    },
  },
  mounted() {
    if ((this.value && this.value.length > 0) || this.value > 0) {
      this.getOptionByValue().length > 0
        ? (this.selected = this.getOptionByValue()[0])
        : (this.selected = this.options[0]);
    } else {
      this.selected = this.options[0];
    }
  },
};
</script>

<style scoped>
.options-wrapper {
  @apply absolute z-40 w-full max-h-40 text-sm 
        flex flex-col items-start mt-9 bg-gray-100 
        rounded-md overflow-auto border-2;
}

.option {
  @apply w-full px-2 py-1 bg-gray-100 rounded-md 
    hover:bg-gray-200 focus:bg-gray-300;
}
</style>
