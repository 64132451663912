<template>
  <div class="py-2">
    <a href="#" class="font-bold text-lg py-4" @click.prevent="setActive()">
      <div class="flex flex-row items-center justify-between">
        {{ title }}

        <svg
          v-if="isEnabled"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          :fill="[isActive ? '#24AD69' : '#BDC9D5']"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.42606 0.0322803L0.111468 0.03228L3.76876 6.3669L7.42606 0.0322803Z"
          />
        </svg>
        <svg
          v-else
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 6H10V4C10 1.79395 8.20605 0 6 0C3.79395 0 2 1.79395 2 4V6H1.5C0.67334 6 0 6.67261 0 7.5V14.5C0 15.3274 0.67334 16 1.5 16H10.5C11.3267 16 12 15.3274 12 14.5V7.5C12 6.67261 11.3267 6 10.5 6ZM3.33337 4C3.33337 2.5293 4.5293 1.33337 6 1.33337C7.4707 1.33337 8.66663 2.5293 8.66663 4V6H3.33337V4Z"
            fill="#24AD69"
          />
        </svg>
      </div>
    </a>

    <div class="" v-if="isActive && isEnabled">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditorItem",
  model: {
    prop: "activeTab",
    event: "set-active",
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: String,
      default: "General",
    },
  },
  methods: {
    setActive() {
      if (this.isEnabled === true) {
        this.$emit("set-active", this.title);
      }
    },
  },
  computed: {
    isActive() {
      return this.title === this.activeTab;
    },
  },
};
</script>
